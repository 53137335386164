import { Navbar } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import styled from 'styled-components';
import useFetchVideos from '@/hooks/video/useFetchVideos';
import useFetchVideoHashtags from '@/hooks/video/hashtag/useFetchVideoHashtags';
import useQueryString from '@/hooks/useQueryString';
import { VideoTab } from '@/enum/video';
import Flex from '@/components/common/Flex';
import CustomLink from '@/components/common/CustomLink';
import DesktopHashtagList from '@/components/video/hashtag/Desktop';
import MobileHashtagList from '@/components/video/hashtag/Mobile';
import VideoCardList from '@/components/video/VideoCardList';
import CustomNavbar from '../components/custom-navbar';
import Tab from '../components/tab/tab';
import LoginChecker from '../components/login-checker';

const MenuNavbar = styled(Navbar)`
  padding: 0;
`;

function Main() {
  const { query } = useQueryString();

  const hashtagId = query?.hashtag;
  const currentTabId = query?.tab || VideoTab.ALL;

  const [cookies] = useCookies();

  const { visibleVideoHashtags, hashtagsLoading } = useFetchVideoHashtags({
    page: 1,
    size: 1000,
    sort: 'createdAt,desc',
  });

  const { videos, videosLoading, videosMoreLoading, fetchNextPage } =
    useFetchVideos({
      size: 20,
      sort: 'kakaoId,desc',
      status: currentTabId === VideoTab.ALL ? '' : currentTabId?.toUpperCase(),
      hashtag: hashtagId === 'all' ? '' : hashtagId,
    });

  useBottomScrollListener(() => fetchNextPage(), {
    triggerOnNoScroll: true,
  });

  return (
    <div>
      <CustomNavbar hideShadow />

      <div>
        <LoginChecker />

        <Flex alignItems='flex-start'>
          <DesktopHashtagList
            hashtagId={hashtagId}
            hashtags={visibleVideoHashtags}
            hashtagsLoading={hashtagsLoading}
          />

          <MainContentsWrapper>
            <Flex
              gap='15px'
              flexDirection='column'
              alignItems
            >
              <MenuNavbar>
                <div>
                  <Flex>
                    <CustomLink
                      href={{
                        query: {
                          ...query,
                          tab: VideoTab.ALL,
                        },
                      }}
                    >
                      <Tab
                        id={VideoTab.ALL}
                        title='전체'
                        active={currentTabId === VideoTab.ALL}
                      />
                    </CustomLink>

                    <CustomLink
                      href={{
                        query: {
                          ...query,
                          tab: VideoTab.UNWATCHED,
                        },
                      }}
                    >
                      <Tab
                        id={VideoTab.UNWATCHED}
                        style={{
                          display:
                            cookies?.type === 'organization-member'
                              ? 'none'
                              : '',
                        }}
                        title='미수강'
                        active={currentTabId === VideoTab.UNWATCHED}
                      />
                    </CustomLink>

                    <CustomLink
                      href={{
                        query: {
                          ...query,
                          tab: VideoTab.WATCHED,
                        },
                      }}
                    >
                      <Tab
                        id={VideoTab.WATCHED}
                        style={{
                          display:
                            cookies?.type === 'organization-member'
                              ? 'none'
                              : '',
                        }}
                        title='수강'
                        active={currentTabId === VideoTab.WATCHED}
                      />
                    </CustomLink>
                  </Flex>
                </div>
              </MenuNavbar>

              <MobileHashtagList
                hashtagId={hashtagId}
                hashtags={visibleVideoHashtags}
                hashtagsLoading={hashtagsLoading}
              />

              <VideoCardList
                size={20}
                status={currentTabId}
                videos={videos}
                videosLoading={videosLoading}
                videosMoreLoading={videosMoreLoading}
                active={currentTabId}
                hashtag={hashtagId}
              />
            </Flex>
          </MainContentsWrapper>
        </Flex>
      </div>
    </div>
  );
}

const MainContentsWrapper = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
`;

export default Main;
