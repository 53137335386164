import CustomNavbar from '../components/custom-navbar';
import CustomContainer from '../components/custom-container';
import VideoPlayer from '../components/video/video-player';

import styled from 'styled-components';
import ScrollToTop from '../components/scroll-to-top';

import * as CssHelper from '../utils/css-helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import ViewMore from '../components/view-more';

const Section = styled.div`
  margin: 0 10px;

  @media only screen and (min-width: ${CssHelper.BREAK_POINT_MOBILE}px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const DetailContainer = styled.div`
  padding: 10px 0;
  margin-bottom: 15px;
`;

const Title = styled.span`
  display: block;

  font-weight: 500;
  font-size: 1.25rem;
`;

const SubContent = styled.div`
  margin-top: 5px;
  font-size: 1rem;
  color: #848c9c;
`;

const Date = styled.span`
  font-size: 0.95rem;
  font-weight: 500;
`;

const Description = styled.div`
  margin-top: 15px;
  color: #444c5c;
`;

export default function NoAuthView({ kakaoId, title, description }) {
  return (
    <div>
      <CustomNavbar
        hideShadow
        hideSearchBar
        disableMainLink
      />
      <CustomContainer>
        <ScrollToTop />
        <VideoPlayer video={{ kakaoId: kakaoId, title: title }} />
        <Section>
          <DetailContainer>
            <Title>{title}</Title>
            <SubContent>
              <Date>
                <FontAwesomeIcon
                  icon={faClock}
                  style={{ marginRight: '5px' }}
                />
                <span>2021/05/27</span>
              </Date>
              <Description>
                <ViewMore text={description} />
              </Description>
            </SubContent>
          </DetailContainer>
        </Section>
      </CustomContainer>
    </div>
  );
}
