import { useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { videoCacheKey } from '@/lib/query/video';
import isEmpty from '@/utils/isEmpty';
import { videoRepository } from '@/repositories/video';

const useFetchVideos = ({ size, sort, hashtag, status, keyword }) => {
  const videoRequest = {
    size,
    sort,
    ...(!isEmpty(hashtag) && {
      hashtag,
    }),
    ...(!isEmpty(status) && {
      status,
    }),
    ...(!isEmpty(keyword) && {
      keyword,
    }),
  };

  const {
    data: videosResponses,
    isInitialLoading: videosLoading,
    isFetching: videosFetching,
    isFetchingNextPage: videosMoreLoading,
    fetchNextPage: fetchNextPageFunc,
  } = useInfiniteQuery({
    queryKey: videoCacheKey.videos(videoRequest),
    queryFn: ({ pageParam = 0 }) =>
      videoRepository.fetchVideos({
        ...videoRequest,
        page: pageParam,
      }),
    getNextPageParam: (currentVideo, allVideos) => {
      return currentVideo?.last ? undefined : allVideos.length;
    },
    staleTime: 0,
    cacheTime: 0,
  });

  const {
    last,
    totalElements: totalVideos,
    totalPages,
    content: videos,
  } = useMemo(() => {
    if (
      isEmpty(videosResponses) ||
      isEmpty(videosResponses.pages[0].data.content)
    ) {
      return {
        last: true,
        totalElements: 0,
        totalPages: 0,
        content: [],
      };
    }

    return {
      ...videosResponses.pages[videosResponses.pages.length - 1].data,
      content: (videosResponses?.pages).flatMap(({ data }) => data.content),
    };
  }, [videosResponses]);

  const fetchNextPage = () => {
    if (videosFetching || last) {
      return;
    }

    fetchNextPageFunc();
  };

  return {
    videosLoading,
    videosFetching,
    videosMoreLoading,
    fetchNextPage,
    last,
    totalVideos,
    totalPages,
    videos,
  };
};

export default useFetchVideos;
