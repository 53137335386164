import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import cookie from 'js-cookie';
import useFetchMyInfo from '@/hooks/merchant/useFetchMyInfo';

function LoginChecker({ to, errorTo = '/please-login', onCheckFinished }) {
  const { userInfo, userInfoError, userInfoLoading } = useFetchMyInfo();

  useEffect(() => {
    if (userInfoLoading) {
      return;
    }

    onCheckFinished?.call(undefined, !!userInfoError);

    if (!userInfoError && !!userInfo) {
      cookie.set('type', userInfo.dtype);
    }
  }, [onCheckFinished, userInfo, userInfoError, userInfoLoading]);

  return (
    <div>
      {userInfoLoading ? (
        <div></div>
      ) : !userInfoError ? (
        !!to ? (
          <Redirect to={to} />
        ) : (
          <></>
        )
      ) : !!errorTo ? (
        <Redirect to={errorTo} />
      ) : (
        <></>
      )}
    </div>
  );
}

export default LoginChecker;
