export const requireNonEmptyElseGet = (object, supplier) => {
  if (!object) {
    return supplier?.apply();
  }
  return object;
};

export const requireNonEmptyElse = (object, alternative) => {
  if (!object) {
    return alternative;
  }
  return object;
};

export const ifEmptyElseGet = (target, whenNotEmpty, whenEmpty) => {
  if (!target) {
    return whenEmpty;
  }
  return whenNotEmpty;
};

export const apply = (func, ...args) => {
  func?.apply(null, args);
};

const Objects = {
  requireNonEmptyElseGet,
  requireNonEmptyElse,
  ifEmptyElseGet,
  apply,
};
export default Objects;
