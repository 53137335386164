import { memo, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { VideoTab } from '@/enum/video';
import useElementDimensions from '@/hooks/use-element-dimensions';
import isEmpty from '@/utils/isEmpty';
import VideoCardListSkeleton from '@/containers/video-card-list-skeleton';
import CustomLink from '@/components/common/CustomLink';
import VideoCard from '../video-card';

const emptyMessage = {
  [VideoTab.ALL]: '등록된 영상이 없습니다.',
  [VideoTab.UNWATCHED]: '미수강한 영상이 없습니다.',
  [VideoTab.WATCHED]: '수강한 영상이 없습니다.',
  [VideoTab.SEARCH]: '검색된 영상이 없습니다.',
};

const VideoCardList = ({
  size,
  status,
  videos,
  videosLoading,
  videosMoreLoading,
  hashtag,
}) => {
  const ref = useRef(null);

  const { width } = useElementDimensions(ref);

  const videoWidth = useMemo(
    () => (width <= 475 ? (width / 2 < 30 ? 0 : width / 2 - 30) : 202),
    [width],
  );
  const videoHeight = 195;

  if (!videosLoading && isEmpty(videos)) {
    return <Empty>{emptyMessage[status]}</Empty>;
  }

  return (
    <VideoCardListWrapper
      ref={ref}
      width={`${videoWidth}px`}
    >
      {videosLoading ? (
        <VideoCardListSkeleton
          count={size}
          width={`${videoWidth}px`}
          height={`${videoHeight}px`}
        />
      ) : (
        videos.map((video, idx) => (
          <div onClick={()=>{
            sessionStorage.setItem('hashTag', hashtag)
            sessionStorage.setItem('status', status);
          }}>
          <CustomLink
            key={idx}
            href={{
              pathname: `/view/${video.id}`,
            }}
          >
            <VideoCard
              key={video.id}
              video={video}
              width={`${videoWidth}px`}
            />
          </CustomLink>
          </div>
        ))
      )}

      {videosMoreLoading && (
        <VideoCardListSkeleton
          count={size}
          width={`${videoWidth}px`}
          height={`${videoHeight}px`}
        />
      )}
    </VideoCardListWrapper>
  );
};

const VideoCardListWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: grid;
  justify-content: center;
  grid-template-columns: ${({ width }) => `repeat(auto-fill, ${width})`};
  grid-column-gap: 1rem;

  & > span {
    display: inline-block;
  }

  .react-loading-skeleton {
    margin-right: 9px;
    margin-bottom: 5px;
  }
`;

const Empty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 1.25rem;
  color: gray;
  z-index: -1;
`;

export default memo(VideoCardList);
