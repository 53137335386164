import { useEffect, useState } from 'react';
import styled from 'styled-components';

const ThumbnailBox = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  background: #f4f4f4;
  border: ${(props) =>
    props.watched ? '3px solid #ff3a24' : '1px solid #DFDFDF'};
  border-radius: 3px;

  img {
    width: 100%;
    height: auto;
  }

  .watched-label {
    display: ${(props) => (props.watched ? 'inherit' : 'none')};
  }
`;

const WatchedLabel = styled.span`
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 1px 3px;
  border-radius: 2px;

  background-color: #ff3a24;
  color: white;
  font-weight: bold;
  font-size: 0.75rem;
`;

const THUMBNAIL_PREFIX =
  'https://t1.daumcdn.net/thumb/C240x140.fjpg.q75/?fname=';

export default function VideoThumbnail({ title, url, watched, styleApplier }) {
  const [StyledThumbnailBox, setStyleThumbnailBox] = useState(ThumbnailBox);
  useEffect(() => {
    if (styleApplier) {
      setStyleThumbnailBox(styleApplier(ThumbnailBox));
    }
  }, [styleApplier]);

  return (
    <StyledThumbnailBox watched={watched}>
      <img
        src={THUMBNAIL_PREFIX + url}
        alt={title + '의 썸네일 이미지'}
        loading='lazy'
      />

      <WatchedLabel className='watched-label'>시청완료</WatchedLabel>
    </StyledThumbnailBox>
  );
}
