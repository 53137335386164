import { useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import UserContext from '@/contexts/user-context';
import Routes from '../routes';

import 'bootstrap/dist/css/bootstrap.min.css';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

dayjs.locale('ko');

function App() {
  const [user, setUser] = useState(null);

  const userContext = {
    user,
    setUser,
  };

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: Infinity,
            cacheTime: Infinity,
            refetchOnWindowFocus: false,
            retry: false,
            useErrorBoundary: false,
          },
        },
      }),
  );

  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <UserContext.Provider value={userContext}>
          <Routes />
        </UserContext.Provider>
      </CookiesProvider>
    </QueryClientProvider>
  );
}

export default App;
