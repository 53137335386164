import { authAxios } from '@/lib/axios';

class VideoHashtagRepository {
  async fetchVideoHashtags(pageRequest) {
    const url = '/videos/hashtags';

    const { data } = await authAxios.get(url, {
      params: pageRequest,
    });

    return data;
  }
}

export const videoHashtagRepository = new VideoHashtagRepository();
