import { StrictMode } from 'react';
import App from '../app';

const Root = () => {
  return (
    <StrictMode>
      <App />
    </StrictMode>
  );
};

export default Root;
