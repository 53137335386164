import { useLocation } from 'react-router-dom';
import { parseQuery } from '@/utils/query-string';

const useQueryString = () => {
  const { search } = useLocation();

  const query = parseQuery(search);

  return {
    query,
  };
};

export default useQueryString;
