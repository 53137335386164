import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';

import styled from 'styled-components';

import * as CssHelper from '../utils/css-helper';
import { useCallback, useState } from 'react';

const StyledNavbar = styled(Navbar)`
  font-family: 'notosanskr' !important;
  // border-bottom: 1px solid lightgray;
  ${(props) =>
    !props.hideShadow
      ? 'box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.12);'
      : 'border-bottom: 1px solid #DFDFDF;'}
  background: white;
`;

const StyledForm = styled(Form)`
  input[type='text'] {
    width: 100%;
  }

  flex-grow: 1;

  @media only screen and (min-width: ${CssHelper.BREAK_POINT_TABLET}px) {
    width: 100%;
    max-width: 500px;
    flex-grow: 0;
    margin: 0 auto;
    padding-right: 90px;
  }
`;

const Brand = styled(Navbar.Brand)`
  span {
    font-size: 18px;
    font-weight: bold;
    padding-top: 3px;
    margin-left: 10px;

    display: none;

    @media only screen and (min-width: ${CssHelper.BREAK_POINT_TABLET}px) {
      display: inline;
    }
  }
`;

export default function CustomNavbar({
  hideShadow,
  keyword = '',
  hideSearchBar,
  disableMainLink,
}) {
  const [keywordValue, setKeywordValue] = useState(keyword);
  const handleChange = useCallback(
    (event) => setKeywordValue(event.target.value),
    [],
  );

  return (
    <StyledNavbar
      bg='#fff'
      expand='lg'
      sticky='top'
      hideShadow={hideShadow}
    >
      <Brand href={disableMainLink ? '#' : '/'}>
        <img
          src='/logo.png'
          alt='상인정보통 로고'
          width='30px'
        />
        <span>상인정보통</span>
      </Brand>
      {hideSearchBar ? (
        <></>
      ) : (
        <StyledForm
          method='get'
          action='/search'
          inline
        >
          <FormControl
            type='text'
            name='keyword'
            placeholder='검색'
            value={keywordValue}
            onChange={handleChange}
          />
        </StyledForm>
      )}
    </StyledNavbar>
  );
}
