import { useQuery } from '@tanstack/react-query';
import { userCacheKey } from '@/lib/query/user';
import { userRepository } from '@/repositories/user';

const useFetchMyInfo = () => {
  const {
    data: userInfo,
    error: userInfoError,
    isInitialLoading: userInfoLoading,
    isFetched: userFetched,
  } = useQuery({
    queryKey: userCacheKey.ME,
    queryFn: userRepository.fetchMyInfo,
    select: ({ data }) => data,
  });

  return {
    userInfo,
    userInfoError,
    userInfoLoading,
    userFetched,
  };
};

export default useFetchMyInfo;
