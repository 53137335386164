import styled from 'styled-components';

const Container = styled.div`
  display: inline-block;
  font-size: 0.87rem;
  padding: 10px 20px 9px 20px;
  border-bottom: ${(props) => (props.active ? '2px solid #F6D146' : '0px')};

  font-weight: 600;
  color: ${(props) => (props.active ? '#32353B' : '#6C7280')};

  cursor: pointer;
`;

export default function Tab({ id, style, title, active, selected }) {
  return (
    <Container
      active={active}
      style={style}
    >
      {title}
    </Container>
  );
}
