import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import isEmpty from '@/utils/isEmpty';
import HashtagItem from '../Item';

const DesktopHashtagList = ({ hashtagId, hashtags, hashtagsLoading }) => {
  if (!hashtagsLoading && isEmpty(hashtags)) {
    return <></>;
  }

  return (
    <FixedContainer>
      <FlexWrapper>
        {hashtagsLoading ? (
          Array.from({ length: 10 }).map((_, index) => (
            <Skeleton
              key={index}
              width='80px'
              height='35px'
            />
          ))
        ) : (
          <>
            <HashtagItem
              id='all'
              name='전체'
              selected={isEmpty(hashtagId) || hashtagId === 'all'}
            />
            {hashtags?.map(({ id, name }) => (
              <HashtagItem
                key={id}
                id={id}
                name={name}
                selected={+hashtagId === +id}
              />
            ))}
          </>
        )}
      </FlexWrapper>
    </FixedContainer>
  );
};

const FixedContainer = styled.aside`
  min-width: 250px;
  max-width: 250px;
  margin-top: 40px;
  padding: 1.5rem;
  position: sticky;
  top: 95px;
  left: 0;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px 16px;
`;

export default DesktopHashtagList;
