import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Main from '@/pages/main';
import Search from '@/pages/search';
import View from '@/pages/view';
import NotFound from '@/pages/not-found';
import NoAuthView from '@/pages/no-auth-view';
import UserNotFound from '@/pages/user-not-found';
import Terms from '@/pages/terms';
import Login from '@/pages/login';
import PleaseLogin from '@/pages/please-login';

const HOWTOVIEW_TITLE = '[공지] 상인정보통 정보등록 및 교육수강방법';

const HOWTOVIEW_DESCRIPTION =
  '#상인정보통 #소상공인 #전통시장 #시장교육 #상인교육\n\n전통시장 상인을 위해 탄생한 "상인정보통"\n채널추가부터 정보등록, 교육수강방법까지 다~ 담은 안내영상만 따라해보세요!\n이제부터 모바일로 유용한 교육정보를 손쉽게 받아보실 수 있답니다👌\n전통시장 상인을 위한 딱 맞는 교육영상! 앞으로 많이 기대해주세요💖';

const HOWTOSIGNUP_TITLE = '[공지] 상인정보통 정보등록 및 교육수강방법';
const HOWTOSIGNUP_DESCRIPTION =
  '#상인정보통 #소상공인 #전통시장 #시장교육 #상인교육\n\n전통시장 상인을 위해 탄생한 "상인정보통"\n채널추가부터 정보등록, 교육수강방법까지 다~ 담은 안내영상만 따라해보세요!\n이제부터 모바일로 유용한 교육정보를 손쉽게 받아보실 수 있답니다👌\n전통시장 상인을 위한 딱 맞는 교육영상! 앞으로 많이 기대해주세요💖';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path='/'
          component={Main}
        />
        <Route
          exact
          path='/search'
          component={Search}
        />
        <Route
          exact
          path='/view/:id'
          component={View}
        />
        <Route
          exact
          path='/how-to-sign-up'
        >
          <NoAuthView
            kakaoId='430153483'
            title={HOWTOSIGNUP_TITLE}
            description={HOWTOSIGNUP_DESCRIPTION}
          />
        </Route>
        <Route
          exact
          path='/how-to-view'
        >
          <NoAuthView
            kakaoId='430153483'
            title={HOWTOVIEW_TITLE}
            description={HOWTOVIEW_DESCRIPTION}
          />
        </Route>
        <Route
          exact
          path='/user-not-found'
          component={UserNotFound}
        />
        <Route
          exact
          path='/login'
          component={Login}
        />
        <Route
          exact
          path='/please-login'
          component={PleaseLogin}
        />
        <Route
          exact
          path='/terms'
          component={Terms}
        />
        <Route
          path='*'
          component={NotFound}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
