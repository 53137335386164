import { forwardRef } from 'react';
import styled from 'styled-components';

const Flex = forwardRef(({ className, children, ...props }, ref) => {
  return (
    <FlexContainer
      {...props}
      ref={ref}
      className={className}
    >
      {children}
    </FlexContainer>
  );
});

Flex.displayName = 'Flex';

const FlexContainer = styled.div`
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  height: ${({ height }) => height};
  display: flex;
  gap: ${({ gap }) => gap};
  flex: ${({ flex }) => flex};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  position: ${({ position }) => position};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  border-radius: ${({ borderRadius }) => borderRadius};
  border: ${({ border }) => border?.all};
  border-top: ${({ border }) => border?.top};
  border-left: ${({ border }) => border?.left};
  border-right: ${({ border }) => border?.right};
  border-bottom: ${({ border }) => border?.bottom};
  background-color: ${({ backgroundColor }) => backgroundColor};
  overflow: ${({ overflow }) => overflow};
  cursor: ${({ cursor }) => cursor};
`;

export default Flex;
