import { authAxios } from '@/lib/axios';

class VideoRepository {
  async fetchVideos(videosRequest) {
    const url = '/videos';

    const { data } = await authAxios.get(url, {
      params: videosRequest,
    });

    return data;
  }
}

export const videoRepository = new VideoRepository();
