import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Linkify from 'react-linkify';

const ToggleButton = styled.span`
  color: black;
  cursor: pointer;
  &:hover {
    color: #6c7280;
  }
`;

export default function ViewMore({ text, limit = 80 }) {
  const [collapsedString, setCollapsedString] = useState([]);
  const [expandedString, setExpandedString] = useState([]);
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    if (!text) {
      return;
    }
    const splited = text.split('\n');

    const toSpan = (line) => {
      return (
        <span>
          {line}
          <br />
        </span>
      );
    };
    let length = 0;
    for (let i = 0; i < splited.length; i++) {
      const element = splited[i];
      length += element.length;
      if (length > limit) {
        setCollapsedString(splited.slice(0, i).map(toSpan));
        break;
      }
    }
    setExpandedString(splited.map(toSpan));
  }, [text, limit, setCollapsedString, setExpandedString]);

  return (
    <div>
      <Linkify>{expand ? expandedString : collapsedString}</Linkify>
      <ToggleButton onClick={() => setExpand(!expand)}>
        {expand ? '접기' : '더보기'}
      </ToggleButton>
    </div>
  );
}
