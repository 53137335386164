import styled from 'styled-components';
import useQueryString from '@/hooks/useQueryString';
import { palette } from '@/styles/palette';
import CustomLink from '@/components/common/CustomLink';

const HashtagItem = ({ id, name, selected }) => {
  const { query } = useQueryString();

  return (
    <HashtagItemContainer
      key={id}
      href={{
        pathname: '/',
        query: {
          ...query,
          hashtag: id,
        },
      }}
      selected={selected}
    >
      <span>{name}</span>
    </HashtagItemContainer>
  );
};

const HashtagItemContainer = styled(CustomLink)`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 7.5px 12.5px;
  font-size: 0.813rem;
  border-radius: 5px;
  border: ${({ selected }) =>
    `1px solid ${selected ? palette.main : '#dfdfdf'}`};
  color: ${({ selected }) => (selected ? palette.white : palette.black)};
  background-color: ${({ selected }) =>
    selected ? palette.main : palette.white};

  &:hover {
    color: ${({ selected }) => (selected ? palette.white : palette.blue)};
  }
`;

export default HashtagItem;
