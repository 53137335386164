import axios from 'axios';
import dotenv from '@/lib/dotenv';

class AuthRepository {
  async refreshToken(refreshTokenDto) {
    const url = '/auth/refresh';

    const { data } = await axios.post(
      `${dotenv.SERVER}${url}`,
      refreshTokenDto,
    );

    return data;
  }
}

export const authRepository = new AuthRepository();
