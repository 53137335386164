import { render } from 'react-dom';
import Root from './components/root';
import reportWebVitals from './reportWebVitals';
import './scss/App.scss';

render(<Root />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
