import { Rating, RatingView } from 'react-simple-star-rating';

export default function StarRating({
  value,
  onValueChanged,
  style,
  readOnly,
  size = 40,
}) {
  return (
    <div
      style={Object.assign(
        readOnly ? { pointerEvents: 'none', userSelect: 'none' } : {},
        style,
      )}
    >
      <Rating
        style={style}
        size={size}
        ratingValue={value}
        onClick={onValueChanged}
      />
    </div>
  );
}
