import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';
import LoginChecker from '../components/login-checker';
import * as CssHelper from '../utils/css-helper';

const StyledContainer = styled(Container)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
  color: #32353b;
`;

const Content = styled.div`
  font-size: 1.3rem;
  text-align: center;
  color: #848c9c;
`;

const Image = styled.img`
  display: block;
  margin: 0 auto;
  width: 100%;

  @media only screen and (min-width: ${CssHelper.BREAK_POINT_MOBILE}px) {
    width: 60%;
  }

  @media only screen and (min-width: ${CssHelper.BREAK_POINT_TABLET}px) {
    width: 40%;
  }
`;

const CustomSpinner = styled(Spinner)`
  width: 100px;
  height: 100px;
  border-width: 15px;
`;

function UserNotFound() {
  const [isLoading, setLoading] = useState(true);
  const [isLogined, setLogined] = useState(false);

  return (
    <StyledContainer>
      <LoginChecker
        errorTo={null}
        to='/'
        onCheckFinished={(logined) => {
          setLoading(false);
          setLogined(logined);
        }}
      />

      {isLoading ? (
        <>
          <CustomSpinner
            animation='border'
            variant=''
            size='lg'
          />
          <Title>로그인중입니다...</Title>
        </>
      ) : (
        <>
          <Image
            src='/images/undraw_forgot_password_gi2d.svg'
            alt='사용자를 찾을 수 없습니다.'
          />
          <Title>사용자를 찾을 수 없습니다.</Title>
          <Content>
            먼저 '상인정보통' 챗봇에서 상인 또는 관계자 등록 후 사용하실 수
            있습니다.
          </Content>
        </>
      )}
    </StyledContainer>
  );
}

export default UserNotFound;
