import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';
import LoginChecker from '../components/login-checker';
import * as CssHelper from '../utils/css-helper';

const StyledContainer = styled(Container)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
  color: #32353b;
`;

const Content = styled.div`
  font-size: 1.3rem;
  text-align: center;
  color: #848c9c;
`;

const Image = styled.img`
  display: block;
  margin: 0 auto;
  width: 100%;

  @media only screen and (min-width: ${CssHelper.BREAK_POINT_MOBILE}px) {
    width: 60%;
  }

  @media only screen and (min-width: ${CssHelper.BREAK_POINT_TABLET}px) {
    width: 40%;
  }
`;

const CustomSpinner = styled(Spinner)`
  width: 100px;
  height: 100px;
  border-width: 15px;
`;

function PleaseLogin() {
  const [isLoading, setLoading] = useState(true);

  return (
    <StyledContainer>
      <LoginChecker
        errorTo={undefined}
        to='/'
        onCheckFinished={(logined) => setLoading(!logined)}
      />

      {isLoading ? (
        <>
          <CustomSpinner
            animation='border'
            variant=''
            size='lg'
          />
          <Title>로그인중입니다...</Title>
        </>
      ) : (
        <>
          <Image
            src='/images/undraw_forgot_password_gi2d.svg'
            alt='카카오톡으로 접속해주세요.'
          />
          <Title>인증 시간 만료</Title>
          <Content>
            인증 시간이 만료되었습니다. 상인정보통 카카오톡 채널에서 다시
            접속해주세요.
          </Content>
        </>
      )}
    </StyledContainer>
  );
}

export default PleaseLogin;
