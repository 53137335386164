import { useState, useEffect } from 'react';

function getElementDimensions(element) {
  return {
    width: element.offsetWidth,
    height: element.offsetHeight,
  };
}

export default function useElementDimensions(ref) {
  const [elementDimensions, setElementDimensions] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      if (!!ref && !!ref.current) {
        const dimensions = getElementDimensions(ref.current);
        if (
          elementDimensions.width !== dimensions.width ||
          elementDimensions.height !== dimensions.height
        ) {
          setElementDimensions(dimensions);
        }
      }
    };
    handleResize();
    // TODO: KDY - 문서 크기 자체가 안바뀌었을 떄도 호출되도록 바꿔야함.
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return elementDimensions;
}
