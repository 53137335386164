import axios from 'axios';
import cookie from 'js-cookie';
import { decode } from 'jsonwebtoken';
import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  REQUEST_TOKEN_KEY,
} from '@/constants/token';
import { authRepository } from '@/repositories/auth';
import isEmpty from '@/utils/isEmpty';

let isRefreshing = false;
let refreshSubscribers = [];

const onTokenRefreshed = (accessToken) => {
  refreshSubscribers.map((callback) => callback(accessToken));
};

const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

export const axiosRequestInterceptor = async (config) => {
  let usingAccessToken = cookie.get(ACCESS_TOKEN_KEY);
  let usingRefreshToken = cookie.get(REFRESH_TOKEN_KEY);

  if (!(isEmpty(usingAccessToken) || isEmpty(usingRefreshToken))) {
    const decodeAccessToken = decode(usingAccessToken);
    const nowDate = Date.now() / 1000;

    if (decodeAccessToken?.exp && decodeAccessToken.exp <= nowDate) {
      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const { data } = await authRepository.refreshToken({
            refreshToken: usingRefreshToken,
          });

          const { accessToken, refreshToken } = data;

          usingAccessToken = accessToken;

          cookie.set(ACCESS_TOKEN_KEY, accessToken);
          cookie.set(REFRESH_TOKEN_KEY, refreshToken);

          axios.defaults.headers.common[
            REQUEST_TOKEN_KEY
          ] = `Bearer ${accessToken}`;
          config.headers[REQUEST_TOKEN_KEY] = `Bearer ${accessToken}`;

          isRefreshing = false;
          onTokenRefreshed(accessToken);

          return config;
        } catch (error) {
          console.log(error);

          isRefreshing = false;
          onTokenRefreshed('');

          return config;
        }
      }

      return new Promise((resolve) => {
        addRefreshSubscriber((accessToken) => {
          config.headers[REQUEST_TOKEN_KEY] = `Bearer ${accessToken}`;
          resolve(config);
        });
      });
    }
  }

  config.headers[REQUEST_TOKEN_KEY] = `Bearer ${usingAccessToken}`;

  return config;
};
