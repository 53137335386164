import { useState } from 'react';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import isEmpty from '@/utils/isEmpty';
import { palette } from '@/styles/palette';
import Flex from '@/components/common/Flex';
import HashtagItem from '../Item';

const MobileHashtagList = ({ hashtagId, hashtags, hashtagsLoading }) => {
  const [showHashtags, setShowHashtags] = useState(false);

  if (!hashtagsLoading && isEmpty(hashtags)) {
    return <></>;
  }

  return (
    <HashtagsContainer>
      <Flex
        gap='2rem'
        justifyContent='space-between'
        alignItems='flex-start'
      >
        <FlexWrapper opened={showHashtags}>
          {hashtagsLoading ? (
            Array.from({ length: 10 }).map((_, index) => (
              <Skeleton
                key={index}
                width='80px'
                height='35px'
              />
            ))
          ) : (
            <>
              <HashtagItem
                id='all'
                name='전체'
                selected={isEmpty(hashtagId) || hashtagId === 'all'}
              />

              {hashtags?.map(({ id, name }) => (
                <HashtagItem
                  key={id}
                  id={id}
                  name={name}
                  selected={+hashtagId === +id}
                />
              ))}
            </>
          )}
        </FlexWrapper>

        <ToggleButton onClick={() => setShowHashtags((prev) => !prev)}>
          {showHashtags ? (
            <>
              <IoIosArrowUp
                fontSize='1.4rem'
                color={palette.white}
              />
            </>
          ) : (
            <>
              <IoIosArrowDown
                fontSize='1.4rem'
                color={palette.white}
              />
            </>
          )}
        </ToggleButton>
      </Flex>
    </HashtagsContainer>
  );
};

const HashtagsContainer = styled.div`
  width: 100%;
  padding: 0 0.75rem;
  overflow-y: visible;
  position: relative;
  display: none;

  @media screen and (max-width: 900px) {
    display: block;
  }
`;

const ToggleButton = styled.button`
  all: unset;
  min-width: 50px;
  min-height: 35px;
  overflow: visible;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin: 0px auto 0 auto;
  font-size: 0.875rem;
  border-radius: 5px;
  color: ${palette.white};
  background-color: ${palette.main};
`;

const FlexWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: ${({ opened }) => (opened ? 'wrap' : 'initial')};
  gap: 12px;
  overflow-x: auto;
  overflow-y: auto;
  max-height: ${({ opened }) => (opened ? '150px' : '45px')};
`;

export default MobileHashtagList;
