import { useCookies } from 'react-cookie';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import dayjs from 'dayjs';

import '../../scss/Ellipsis.scss';
import VideoThumbnail from './video-thumbnail';
import humanReadableDate from '../../utils/human-readable-date';

const Container = styled.div`
  display: inline-block;
  width: ${(props) => (!!props.width ? props.width : '212px')};
  font-size: 1rem;
  text-align: left;

  cursor: pointer;
`;

const Title = styled.span`
  margin-top: 8px;
  margin-bottom: 2px;
  font-weight: 600;
  color: #32353b;
  height: 2.5rem;
`;

const SubContent = styled.span`
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 2px 0 6px;
  color: #848c9c;
  font-size: 0.8rem;
`;

const Date = styled.span`
  margin-left: 5px;
`;

function VideoCard({ dummy, video, width = '202px', height }) {
  const [cookies] = useCookies();

  if (dummy === true) {
    return (
      <Container
        width={width}
        height={height}
      />
    );
  }
  return (
    <Container width={width}>
      <VideoThumbnail
        title={video.title}
        url={video.thumbnailUrl}
        watched={video.watched && cookies?.type === 'merchant'}
      />
      <Title
        className='ellipsis'
        title={video.title}
      >
        {video.title}
      </Title>
      <SubContent>
        <FontAwesomeIcon icon={faClock} />
        {/* <View>{comma(props.video.views)}</View> */}
        {/* <span> • </span> */}
        <Date>{humanReadableDate(dayjs(video.createdAt))}</Date>
      </SubContent>
    </Container>
  );
}

export default VideoCard;
