import { Link } from 'react-router-dom';
import styled from 'styled-components';
import isEmpty from '@/utils/isEmpty';
import VideoListItem from '../components/video/video-list-item';
import VideoListSkeleton from './video-list-skeleton';

export default function VideoList({
  size,
  videos,
  videoLoaded,
  isMoreComingIn,
}) {
  if (videoLoaded && isEmpty(videos)) {
    return <Empty>더 이상 수강할 영상이 없습니다.</Empty>;
  }

  return (
    <div>
      {!videoLoaded ? (
        <VideoListSkeleton count={size} />
      ) : (
        videos.map((video) => (
          <Link
            key={video.id}
            to={{
              pathname: `/view/${video.id}`,
              state: {
                video: video,
              },
            }}
          >
            <VideoListItem
              key={video.id}
              video={video}
            />
          </Link>
        ))
      )}
      {isMoreComingIn ? <VideoListSkeleton count={size} /> : <></>}
    </div>
  );
}

const Empty = styled.div`
  height: 100vh;
  font-size: 1.1rem;
  color: gray;
`;
