import { Link } from 'react-router-dom';
import qs from 'qs';
import styled from 'styled-components';

const CustomLink = ({ className, href, children }) => {
  return (
    <CustomLinkContainer
      className={className}
      to={{
        pathname: href?.pathname,
        search: qs.stringify(href?.query),
      }}
    >
      {children}
    </CustomLinkContainer>
  );
};

const CustomLinkContainer = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export default CustomLink;
