import { useState, useCallback, useEffect, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { apply } from '../../utils/objects';
import isEmpty from '@/utils/isEmpty';

const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
`;

const VideoIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const VideoSkeleton = styled(Skeleton)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

function useInterval(callback, delay) {
  const savedCallback = useRef();
  const savedId = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    return () => clearInterval(savedId.current);
  }, [delay]);

  const play = useCallback(() => {
    if (savedId.current) return;
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    savedId.current = id;
  }, [savedId, delay]);

  const pause = useCallback(() => {
    clearInterval(savedId.current);
    savedId.current = undefined;
  }, [savedId]);

  return [play, pause];
}

function VideoPlayer({ video, isLoading, onPlaybackTimeCount, videoList }) {
  const [playbackTime, setPlaybackTime] = useState(0);
  const iframeRef = useRef(null);
  const src = `https://play-tv.kakao.com/embed/player/cliplink/${video.kakaoId}?service=player_share`;
  const srcWithAutoplay = src + '&autoplay=1';
  const [play, pause] = useInterval(() => {
    setPlaybackTime(playbackTime + 1);
    apply(onPlaybackTimeCount, playbackTime);
  }, 1000);

  useEffect(() => {
    setPlaybackTime(0);
  }, [video]);

  useEffect(() => {
    const onMessage = (e) => {
      try {
        const data = !!e.data ? JSON.parse(e.data) : {};
        if (data['action'] === 'onPlayEnd') {
          const idx = videoList.findIndex((arr) => arr === video.id);
          if(!isEmpty(videoList[idx+1])){
            if(sessionStorage.getItem('isNext') === 'true') window.location.href = `/view/${videoList[idx+1]}`
          }else{
            pause();
            setPlaybackTime(0);
            apply(onPlaybackTimeCount, 0);
            if (iframeRef && iframeRef.current) {
              iframeRef.current.src = src;
            }
          }
        } else if (data['action'] === 'onVideoStateChange') {
          if (data['args'][0] === 'playing') {
            play();
          } else {
            pause();
          }
        }
      } catch (ignored) {
        // do nothing
      }
    };
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onmessage);
    };
  });

  return (
    <VideoContainer>
      {isLoading ? (
        <VideoSkeleton />
      ) : (
        <VideoIframe
          ref={iframeRef}
          title={video.title}
          width='640'
          height='360'
          src={srcWithAutoplay}
          allowFullScreen
          allow='autoplay; encrypted-media;'
        ></VideoIframe>
      )}
    </VideoContainer>
  );
}

export default VideoPlayer;
