import { authAxios } from '@/lib/axios';

class UserRepository {
  async fetchMyInfo() {
    const url = '/users/me';

    const { data } = await authAxios.get(url);

    return data;
  }
}

export const userRepository = new UserRepository();
