import { useEffect, useState } from 'react';
import axios from 'axios';
import { authAxios } from '@/lib/axios';
import { ifEmptyElseGet, apply } from '../utils/objects';

class CachedSource {
  constructor() {
    this.source = axios.CancelToken.source();
    this.canceled = false;
  }

  token() {
    return this.source.token;
  }

  cancel() {
    this.source.cancel();
    this.canceled = true;
  }
}

export default function useFetch(
  method,
  url,
  {
    lockBeforeTriggered,
    onRequest,
    onResponse,
    onCancel,
    onError,
    headers,
    host,
    ...options
  } = {},
  data,
) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(!lockBeforeTriggered);
  const [source, setSource] = useState(null);

  const [restart, setRestart] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [body, setBody] = useState(data);

  const toggle = (body) => {
    setBody(body);
    setTrigger(true);
    setRestart(!restart);
  };

  const reset = () => {
    setIsLoading(false);
    setResponse(null);
    setError(null);
  };

  useEffect(() => {
    setBody(body);
  }, [data]);

  useEffect(() => {
    if (lockBeforeTriggered && !trigger && isLoading) {
      return;
    }
    if (!!source) {
      source.cancel();
    }
    reset();
    const newSource = new CachedSource();
    setSource(newSource);
    setIsLoading(true);

    if (lockBeforeTriggered && !trigger) {
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      if (!method || !url) {
        setError(new Error('url, method를 입력해주세요.'));
        setIsLoading(false);
        return;
      }

      const dataOption = ifEmptyElseGet(body, { data: body }, {});
      const option = Object.assign(
        {
          method: method,
          url: `/${url}`,
          cancelToken: newSource.token(),
        },
        dataOption,
        options,
      );

      try {
        const response = await authAxios.request(option);

        if (!newSource.canceled) {
          setResponse(response.data);
          apply(onResponse, response);
        } else {
          reset();
          apply(onCancel, option);
          return;
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          if (newSource?.canceled) {
            setIsLoading(false);
          }
          apply(onCancel, option);
          return;
        } else {
          setError(error);
          apply(onError, error, option);
        }
      }
      if (!newSource.canceled) {
        setIsLoading(false);
        setTrigger(false);
      } else {
        reset();
        apply(onCancel, option);
      }
    };
    fetchData();
  }, [method, url, restart, lockBeforeTriggered, body, host]);

  return {
    response,
    error,
    isLoading,
    trigger: toggle,
  };
}
