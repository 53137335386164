import dotenv from '@/lib/dotenv';
import axios from 'axios';

const postWatched = async(req) => {
    const url = `${dotenv.SERVER_URL}check`;

    const { data } = await axios.post(url, {
      params: req,
    });

    return data;
}

export default postWatched;