import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import styled from 'styled-components';
import { VideoTab } from '@/enum/video';
import useQueryString from '@/hooks/useQueryString';
import useFetchVideos from '@/hooks/video/useFetchVideos';
import VideoCardList from '@/components/video/VideoCardList';
import CustomNavbar from '../components/custom-navbar';
import CustomContainer from '../components/custom-container';
import LoginChecker from '../components/login-checker';

const Title = styled.div`
  margin: 20px 0;

  font-size: 1.25rem;
  text-align: center;

  .bold {
    font-weight: bold;
  }

  .accent {
    color: #ffa700;
  }
`;

const Divider = styled.div`
  margin: 5px auto;
  width: 200px;
  border-bottom: 1px solid lightgray;
`;

export default function Search() {
  const { query } = useQueryString();

  const keyword = query?.keyword || '';

  const {
    videos,
    videosLoading,
    videosMoreLoading,
    totalVideos,
    fetchNextPage,
  } = useFetchVideos({
    size: 20,
    sort: 'kakaoId,desc',
    keyword,
  });

  useBottomScrollListener(() => fetchNextPage(), {
    triggerOnNoScroll: true,
  });

  return (
    <div>
      <CustomNavbar
        hideShadow
        keyword={keyword}
      />
      <CustomContainer className='App'>
        <LoginChecker />
        <Title>
          '<span className='bold'>{keyword}</span>' 전체 검색결과
          <br />
          <Divider />총 <span className='accent bold'>{totalVideos}</span> 건이
          검색되었습니다.
        </Title>

        <VideoCardList
          size={20}
          status={VideoTab.SEARCH}
          videos={videos}
          videosLoading={videosLoading}
          videosMoreLoading={videosMoreLoading}
        />
      </CustomContainer>
    </div>
  );
}
