import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 5rem;
  font-weight: 900;
`;

export default function NotFound({
  title = '404',
  content = '페이지를 찾을 수 없습니다.',
}) {
  const history = useHistory();
  return (
    <Container>
      <Title>{title}</Title>
      {content}
      <Button
        className='mt-3'
        onClick={() => history.push('/')}
      >
        처음으로
      </Button>
    </Container>
  );
}
