import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { videoCacheKey } from '@/lib/query/video';
import { videoHashtagRepository } from '@/repositories/video/hashtag';

const useFetchVideoHashtags = ({ page, size, sort }) => {
  const pageRequest = {
    page: page - 1,
    size,
    sort,
  };

  const { data: hashtagsResponse, isInitialLoading: hashtagsLoading } =
    useQuery({
      queryKey: videoCacheKey.hashtag.pageable(pageRequest),
      queryFn: () => videoHashtagRepository.fetchVideoHashtags(pageRequest),
      select: ({ data }) => data,
    });

  const hashtags = hashtagsResponse?.content || [];

  const visibleVideoHashtags = useMemo(() => {
    return [...(hashtags || [])].filter(
      ({ visibleVideoCount }) => visibleVideoCount > 0,
    );
  }, [hashtags]);

  return {
    hashtags,
    visibleVideoHashtags,
    hashtagsLoading,
  };
};

export default useFetchVideoHashtags;
