import styled from 'styled-components';
import CustomContainer from '../components/custom-container';
import CustomNavbar from '../components/custom-navbar';

const CustomDiv = styled.div`
  ol {
    line-height: 1.75rem;
  }
  ol > li {
    padding-bottom: 20px;
    font-size: 1.15rem;
  }
  ol > li > ul {
    font-size: 1rem;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Accent = styled.span`
  color: #234b7e;
  font-weight: bold;
`;

export default function Terms() {
  return (
    <CustomDiv>
      <CustomNavbar hideSearchBar={true} />
      <CustomContainer>
        <div
          style={{
            paddingTop: '40px',
            paddingLeft: '10px',
            paddingRight: '10px',
            margin: '0 auto',
            maxWidth: '900px',
          }}
        >
          <h1
            style={{
              fontWeight: '900',
              fontSize: '30px',
              textAlign: 'center',
              background: 'white',
              color: '#1c3669',
              borderTop: '1px solid #1c3669',
              borderBottom: '1px solid #1c3669',
              padding: '10px',
            }}
          >
            개인정보 수집·이용 및 제공 동의에 대한 안내
          </h1>
          <div
            style={{
              padding: '3px',
              border: '1px solid #c4c7ce',
              borderRadius: '5px',
              marginBottom: '20px',
            }}
          >
            <div
              style={{
                padding: '15px 20px',
                border: '1px solid #c4c7ce',
                borderRadius: '5px',
              }}
            > 
              방송원에서는 상인정보통 서비스 제공 및 시장 매니저 역량 강화 교육 과정 지원을 위한 필수정보와 정보 주체 각각의 기호와 필요에 맞는 서비스를 제공하기 위한 선택정보로 구분하여 다음의 정보를 수집하고 있습니다. 본인의 개인정보를 수집·이용·제공하고자 하는 경우에는 「개인정보보호법」제15조 제1항 제1호, 제17조 제1항 제1호, 제24조 제1항 제1호에 따라 본인의 동의를 얻어야 합니다. 이에 본인은 아래의 내용과 같이 본인의 개인정보를 수집·이용·제공하는 것에 동의합니다.
            </div>
          </div>
          <ol>
            <li>
              개인정보의 수집·이용에 관한 사항
              <ul>
                <li>
                  <Bold>(수집·이용 목적)</Bold>
                  <br />①전통시장 및 상점가 정책정보·이슈 등 정보제공서비스 제공 및 회원관리(이용자식별, 서비스 가입 의사 확인 등), <br />
                  ②소상공인시장진흥공단이 추진하는 시장 매니저 역량 강화 교육 과정 지원자로서 시장경영패키지지원사업 전문기관*, 중소벤처기업부 및 공단, 관할 지방자치단체 및 시장 상인회에 개인정보를 제공하기 위한 수집·이용<br />
                  * 전문기관은 추후 별도 공모 후 선정 예정
                </li>
                <li>
                  <Bold>(개인정보의 수집항목)</Bold>
                  <br /> 성명(한글), 연락처, 생년월일 등
                </li>
                <li>
                  <Bold>(보유 및 이용 기간)</Bold>
                  <br /> 수집·이용에 관한 동의 일로부터 탈퇴 시까지 보유·이용
                </li>
                <li>
                  <Bold>
                    (동의를 거부할 권리 및 동의를 거부할 경우의 불이익)
                  </Bold>
                  <br />동의를 거부할 권리가 있으며, 위 개인정보의 수집·이용에 동의하지 않으실 경우, “시장 매니저 역량 강화과정 참여 및 지원, 선정 등”, “상인정보통(정보제공서비스)” 이용이 제한 될 수 있습니다.
                </li>
              </ul>
            </li>
            <li>
              수집한 개인정보의 제3자 제공에 관한 사항
              <ul>
                <li>
                  <Bold>(개인정보를 제공받는 자)</Bold>
                  <br /> 중소벤처기업부, 소상공인시장진흥공단, 시장경영패키지지원사업 전문기관* <br />
                  * 전문기관은 추후 별도 공모 후 선정 예정
                </li>
                <li>
                  <Bold>(제공받는 자의 개인정보 이용 목적)</Bold>
                  <br />①전통시장 및 상점가 정책정보·이슈 등 정보제공서비스 제공 및 회원관리(이용자식별, 서비스 가입 의사 확인 등), ②시장 매니저 역량 강화과정 교육생 선정을 위한 이력 확인
                </li>
                <li>
                  <Bold>(제공하는 개인정보 항목)</Bold>
                  <br /> 성명(한글), 연락처, 생년월일 등
                </li>
                <li>
                  <Bold>(제공받는 자의 개인정보 보유 및 이용 기간)</Bold>
                  <br /> 수집·이용에 관한 동의 일로부터 탈퇴 시까지 보유·이용
                </li>
                <li>
                  <Bold>
                    (동의를 거부할 권리 및 동의를 거부할 경우의 불이익)
                  </Bold>
                  <br /> 동의를 거부할 권리가 있으며, 위 개인정보의 수집·이용에 동의하지 않으실 경우, “시장 매니저 역량 강화과정 참여 및 지원, 선정 등”, “상인정보통(정보제공서비스)” 이용이 제한 될 수 있습니다.
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div
          style={{
            paddingTop: '40px',
            paddingLeft: '10px',
            paddingRight: '10px',
            margin: '0 auto',
            maxWidth: '900px',
          }}
        >
          <h1
            style={{
              fontWeight: '900',
              fontSize: '30px',
              textAlign: 'center',
              background: 'white',
              color: '#1c3669',
              borderTop: '1px solid #1c3669',
              borderBottom: '1px solid #1c3669',
              padding: '10px',
            }}
          >
            개인정보 수집·이용 및 제공에 대한 안내
          </h1>
          <div
            style={{
              padding: '3px',
              border: '1px solid #c4c7ce',
              borderRadius: '5px',
              marginBottom: '20px',
            }}
          >
            <div
              style={{
                padding: '15px 20px',
                border: '1px solid #c4c7ce',
                borderRadius: '5px',
              }}
            > 
              방송원에서는 상인정보통 서비스 제공을 위한 필수정보와 정보주체 각각의 기호와 필요에 맞는 서비스를 제공하기 위한 선택정보로 구분하여 다음의 정보를 수집하고 있습니다. 가입정보에 대한 선택정보를 입력하지 않은 경우에도 서비스 이용에는 제한이 없습니다.(단, 정보미기재 시 맞춤형 정보제공 서비스 이용에 제한이 있을 수 있습니다)
            </div>
          </div>
          <ol>
            <li>
              개인정보의 수집·이용에 관한 사항
              <ul>
                <li>
                  <Bold>(수집·이용 목적)</Bold>
                  <br />전통시장 및 상점가 정책정보·이슈 등 정보제공서비스 제공 및 회원관리(이용자식별, 서비스 가입의사 확인 등)
                </li>
                <li>
                  <Bold>(개인정보의 수집항목)</Bold>
                  <br /> 시장 및 상점가 명칭, 연락처, 출생연도 등
                </li>
                <li>
                  <Bold>(보유 및 이용 기간)</Bold>
                  <br /> 수집·이용에 관한 동의 일로부터 탙퇴시까지 보유·이용
                </li>
                <li>
                  <Bold>
                    (동의를 거부할 권리 및 동의를 거부할 경우의 불이익)
                  </Bold>
                  <br />동의를 거부할 권리가 있으며, 동의를 거부할 경우 “상인 정보통(정보제공서비스)” 이용이 제한 될 수 있습니다.
                </li>
              </ul>
            </li>
            <li>
              수집한 개인정보의 제3자 제공에 관한 사항
              <ul>
                <li>
                  <Bold>(개인정보를 제공받는 자)</Bold>
                  <br /> 중소벤처기업부, 소상공인시장진흥공단
                </li>
                <li>
                  <Bold>(제공받는 자의 개인정보 이용 목적)</Bold>
                  <br />전통시장 및 상점가 정책정보·이슈 등 정보제공서비스 제공 및 회원관리(이용자식별, 서비스 가입의사 확인 등)
                </li>
                <li>
                  <Bold>(제공하는 개인정보 항목)</Bold>
                  <br /> 시장 및 상점가 명칭, 연락처, 출생연도 등
                </li>
                <li>
                  <Bold>(제공받는 자의 개인정보 보유 및 이용 기간)</Bold>
                  <br /> 수집·이용에 관한 동의 일로부터 탙퇴시까지 보유·이용
                </li>
                <li>
                  <Bold>
                    (동의를 거부할 권리 및 동의를 거부할 경우의 불이익)
                  </Bold>
                  <br /> 동의를 거부할 권리가 있으며, 동의를 거부할 경우 “상인 정보통(정보제공서비스)” 이용이 제한 될 수 있습니다.
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </CustomContainer>
    </CustomDiv>
  );
}
