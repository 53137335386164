import { useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import styled from 'styled-components';
import cookie from 'js-cookie';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@/constants/token';
import useQueryString from '@/hooks/useQueryString';
import isEmpty from '@/utils/isEmpty';
import LoginChecker from '../components/login-checker';

const StyledContainer = styled.div`
  position: absolute;

  width: 200px;
  height: 150px;
  margin: auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
`;

const CustomSpinner = styled(Spinner)`
  width: 100px;
  height: 100px;
  border-width: 15px;
`;

const Title = styled.div`
  font-size: 1.5rem;
  margin-top: 20px;
  font-weight: 500;
`;

function Login() {
  const { query } = useQueryString();

  const redirectTo = query?.redirectTo ?? '/';

  useEffect(() => {
    if (isEmpty(query?.token) || isEmpty(query?.refreshToken)) {
      return;
    }

    const { token, refreshToken } = query;

    cookie.remove(ACCESS_TOKEN_KEY, { domain: '.center-sangin.com' });
    cookie.remove(REFRESH_TOKEN_KEY, { domain: '.center-sangin.com' });
    cookie.set(ACCESS_TOKEN_KEY, token);
    cookie.set(REFRESH_TOKEN_KEY, refreshToken);
  }, [query]);

  return (
    <StyledContainer>
      <CustomSpinner
        animation='border'
        variant=''
        size='lg'
      />
      <Title>로그인중입니다...</Title>
      <LoginChecker to={redirectTo} />
    </StyledContainer>
  );
}

export default Login;
