import Skeleton from 'react-loading-skeleton';

export default function VideoCardListSkeleton({
  count,
  width = '202px',
  height = '195px',
}) {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <Skeleton
          key={index}
          width={width}
          height={height}
        />
      ))}
    </>
  );
}
